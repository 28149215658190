import { PlayerPage } from "~pages/player/playerPage";
import { platform, PlatformType } from "~ui-lib";

import { navigationStack } from "../main";
import { HomeTab } from "../pages/home/homeTab";
import { PlayerMagnetoPage } from "../pages/player/playerMagnetoPage";
import { pushTabWithMenu } from "../pages/rootPage";
import { MainMenuItemSlug } from "../views/mainMenu/itemMenu";

export const startMultitaskingManager = () => {
  Log.app.info("startMultitaskingManager START");
  document.addEventListener("visibilitychange", () => {
    console.log("visibility change event cought");
    if (document.hidden) {
      // Behavior when application is hidden
      switch (platform.type) {
        case PlatformType.tizen:
          Log.app.log("onVisibilityHide() isTizen true :: exiting player");
          if (navigationStack.topPage instanceof PlayerPage || navigationStack.topPage instanceof PlayerMagnetoPage) {
            navigationStack.topPage.exitPlayer();

            /**
             * Since we are removing top page, we can encounter cases when the navigationStack is empty
             * To prevent app to be stuck without any page, we are pushing the home page.
             */
            if (navigationStack.pages$.value.length <= 0) {
              pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
            }
          }

          break;
        default:
          Log.app.log("onVisibilityHide :: do nothing");
      }
    } else {
      // Behavior when application is resumed
      switch (platform.type) {
        case PlatformType.tizen:
          // We don't need to do anything specific here
          break;
        default:
          Log.app.log("onVisibilityShow :: do nothing");
      }
    }
  });
};
